/* eslint-disable indent */
import { useCookies } from 'vue3-cookies';
import BaseService from './BaseService';

const { cookies } = useCookies({ secure: true });

export default class StoryService extends BaseService {
  static getHeaders(toAppend = {}) {
    return {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    };
  }

  getStoryTypes() {
    return new Promise((resolve, reject) => {
      const apiEndPoint = `${this.baseURL}v1/story-types/`;
      this.http
        .get(apiEndPoint, {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${this.getUserToken()}`,
          },
        })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error.response);
        });
    });
  }

  getAllStories(term) {
    return new Promise((resolve, reject) => {
      let apiEndPoint;
      if (term.searchStory.value) {
        apiEndPoint = `${this.baseURL}v1/stories/for-dashboard/?search=${term.searchStory.value}&orderBy=-updated_at&limit=${term.storyPerPage.value}&offset=${term.currentOffset.value}`;
      } else {
        apiEndPoint = `${this.baseURL}v1/stories/for-dashboard/?orderBy=-updated_at&limit=${term.storyPerPage.value}&offset=${term.currentOffset.value}`;
      }
      // const apiEndPoint = `${this.baseURL}v1/stories/?limit=10&orderBy=-updated_at`;
      this.http
        .get(apiEndPoint, {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${this.getUserToken()}`,
          },
        })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error.response);
        });
    });
  }

  storyUpload(data, token) {
    return new Promise((resolve, reject) => {
      const apiEndPoint = `${this.baseURL}v1/stories/`;
      // console.log(data);
      this.http
        .post(apiEndPoint, data, {
          headers: {
            // eslint-disable-next-line no-underscore-dangle
            'Content-Type': 'multipart/form-data;',
            Authorization: `Bearer ${this.getUserToken()}`,
          },
        })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error.response);
        });
    });
  }

  storyUpdate(id, data) {
    return new Promise((resolve, reject) => {
      const apiEndPoint = `${this.baseURL}v1/stories/${id}/`;
      this.http
        .put(apiEndPoint, data, {
          headers: {
            // eslint-disable-next-line no-underscore-dangle
            'Content-Type': 'multipart/form-data;',
            Authorization: `Bearer ${this.getUserToken()}`,
          },
        })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error.response);
        });
    });
  }

  getStoryDetailsById(id) {
    return new Promise((resolve, reject) => {
      const apiEndPoint = `${this.baseURL}v1/stories/${id}/`;
      this.http
        .get(apiEndPoint, {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${this.getUserToken()}`,
          },
        })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error.response);
        });
    });
  }

  storyDelete(id) {
    return new Promise((resolve, reject) => {
      const apiEndPoint = `${this.baseURL}v1/stories/${id}/`;
      this.http
        .delete(apiEndPoint, {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${this.getUserToken()}`,
          },
        })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error.response);
        });
    });
  }

  getAllDeletedStories(term) {
    return new Promise((resolve, reject) => {
      let apiEndPoint;
      if (term.searchStory.value) {
        apiEndPoint = `${this.baseURL}v1/stories/?search=${term.searchStory.value}&orderBy=-updated_at&limit=${term.perPage.value}&offset=${term.currentOffset.value}&deleted_only=true`;
      } else {
        apiEndPoint = `${this.baseURL}v1/stories/?orderBy=-updated_at&limit=${term.perPage.value}&offset=${term.currentOffset.value}&deleted_only=true`;
      }
      // const apiEndPoint = `${this.baseURL}v1/stories/?limit=10&orderBy=-updated_at`;
      this.http
        .get(apiEndPoint, {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${this.getUserToken()}`,
          },
        })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error.response);
        });
    });
  }

  restoreStory(id) {
    return new Promise((resolve, reject) => {
      const apiEndPoint = `${this.baseURL}v1/stories/restore/${id}`;
      this.http
        .put(apiEndPoint, {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${this.getUserToken()}`,
          },
        })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error.response);
        });
    });
  }

  getAllWithOutLimtStories(term) {
    return new Promise((resolve, reject) => {
      const apiEndPoint = `${this.baseURL}v1/stories/?limit=0&orderBy=-updated_at`;
      this.http
        .get(apiEndPoint, {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${this.getUserToken()}`,
          },
        })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error.response);
        });
    });
  }

  getStoryShieldStories(term) {
    return new Promise((resolve, reject) => {
      const apiEndPoint = `${this.baseURL}v1/stories/?limit=0&orderBy=-updated_at&type__eq=storyshield-story`;
      this.http
        .get(apiEndPoint, {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${this.getUserToken()}`,
          },
        })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error.response);
        });
    });
  }

  retryStoryConversion(id) {
    return new Promise((resolve, reject) => {
      const apiEndPoint = `${this.baseURL}v1/stories/retry-conversion/${id}`;
      this.http
        .post(apiEndPoint, {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${this.getUserToken()}`,
          },
        })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error.response);
        });
    });
  }
}
