/* eslint-disable */
import CLayerBaseService from '@/services/CLayerBaseService';
import { useCookies } from 'vue3-cookies';

const axios = require('axios');

const cLayerBaseService = new CLayerBaseService();

const { cookies } = useCookies({ secure: true });

export default class BaseService {
  constructor() {
    this.baseURL = process.env.VUE_APP_API_URL;
    this.http = axios;
    this.guestToken = process.env.VUE_APP_GUEST_API_TOKEN;
    this.authTokenRequest = null; // Queuing authentication request

    axios.interceptors.response.use(
      (res) => res,
      async (err) => {
        const originalConfig = err.config;
        // eslint-disable-next-line no-underscore-dangle
        if (originalConfig.url === `${this.baseURL}v1/auth/jwt-verify/` && !originalConfig._retry) {
          originalConfig._retry = true;
          this.logout();
        } else if (err.response) {
          if (
            err?.response?.status === 401 &&
            err.response.data.message === 'Unauthorized' &&
            !originalConfig._retry
          ) {
            originalConfig._retry = true;
            try {
              const rs = await this.getAuthToken();
              cookies.set('storyPhoneAdminCookie', {
                access_token: rs.data.accessToken,
              });
              cookies.set('storyPhoneAdminRefreshCookie', {
                refresh_token: rs.data.refreshToken,
              });
              originalConfig.headers.Authorization = `Bearer ${this.getUserToken()}`;
              return axios.request(originalConfig);
            } catch (_error) {
              return Promise.reject(_error);
            }
          }
        }

        return Promise.reject(err);
      }
    );
  }

  refresh(credentials) {
    return new Promise((resolve, reject) => {
      const apiEndPoint = `${this.baseURL}v1/auth/jwt-verify/`;
      this.http
        .post(apiEndPoint, credentials)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error.response.data.errors);
        });
    });
  }

  getUserToken = () => {
    const token = cookies.get('storyPhoneAdminCookie');
    if (!token) {
      cookies.remove('storyPhoneAdminCookie');
      cookies.remove('storyPhoneAdminRefreshCookie');
      localStorage.removeItem('storyPhonesUser');
      window.location.href = '/';
    }
    return token.access_token;
  };

  // eslint-disable-next-line class-methods-use-this
  logout = () => {
    cookies.remove('storyPhoneAdminCookie');
    cookies.remove('storyPhoneAdminRefreshCookie');
    localStorage.removeItem('storyPhonesUser');
    window.location.href = '/';
  };

  /**
   * This function makes a call to get the auth token
   * or it returns the same promise as an in-progress call to get the auth token */
  getAuthToken = () => {
    if (!this.authTokenRequest) {
      this.authTokenRequest = this.makeActualAuthenticationRequest()
        .then((response) => {
          this.resetAuthTokenRequest();
          return response; // Ensure callers get the resolved value
        })
        .catch((error) => {
          this.resetAuthTokenRequest();
          return Promise.reject(error); // Ensure callers get the rejected error
        });
    }
    return this.authTokenRequest;
  };

  makeActualAuthenticationRequest = async () => {
    const rToken = cookies.get('storyPhoneAdminRefreshCookie');
    cLayerBaseService.createAdminToken();
    const rs = await axios.post(`${this.baseURL}v1/auth/jwt-verify/`, {
      refreshToken: rToken.refresh_token,
    });
    return rs;
  };

  resetAuthTokenRequest = () => {
    this.authTokenRequest = null;
  };
}
