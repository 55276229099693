<template lang="html">
  <button
    v-if="!retrySuccess"
    type="button"
    class="border-0 bg-transparent tooltip-wrap h-50"
    :data-bs-original-title="tooltipText"
    data-bs-toggle="tooltip"
    data-bs-container="body"
    @click="retryConversion()"
    ref="tooltipButton"
  >
    <i class="btn btn-outline-primary bi bi-arrow-repeat rounded-5 btn-sm"></i>
    <span class="tooltip h-75">{{ tooltipText }}</span>
  </button>
</template>

<script lang="js">
import StoryService from '@/services/StoryService';
import { computed, ref } from 'vue';

const storyService = new StoryService();

export default {
  name: 'Button',
  props: [
    'conversionStatus', 'storyId', 'setLoading', 'setErrorMsg',
    'setSuccessMsg', 'updateConversionStatus',
  ],
  setup(props) {
    const tooltipText = ref('Retry Convertion');
    const tooltipButton = ref(null);
    const retrySuccess = computed(() => props.conversionStatus === 'send_pending' || props.conversionStatus === 'completed');
    const retryConversion = () => {
      props.setLoading(true);
      storyService.retryStoryConversion(props.storyId).then((res) => {
        props.setLoading(false);
        props.setSuccessMsg(res?.data?.message);
        props.updateConversionStatus(props.storyId);
        retrySuccess.value = false;
        setTimeout(() => {
          props.setSuccessMsg('');
        }, 3000);
      }).catch((error) => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        props.setLoading(false);
        props.setErrorMsg(error?.data);
        setTimeout(() => {
          props.setErrorMsg('');
        }, 3000);
      });
    };
    return {
      tooltipText,
      tooltipButton,
      retrySuccess,
      retryConversion,
    };
  },
};
</script>
