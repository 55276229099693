<template lang="html">
  <button
    type="button"
    class="border-0 bg-transparent tooltip-wrap h-50"
    :data-bs-original-title="tooltipText"
    data-bs-toggle="tooltip"
    data-bs-container="body"
    @click="copyToClipboard(this.file)"
    ref="tooltipButton"
  >
    <i v-if="!copySuccess" class="bi bi-clipboard"></i>
    <i v-else class="bi bi-check2 text-success"></i>
    <span class="tooltip h-75">{{ tooltipText }}</span>
  </button>
</template>

<script lang="js">
import { ref } from 'vue';

export default {
  name: 'Button',
  props: ['file'],
  setup(props) {
    const copySuccess = ref(false);
    const tooltipText = ref('Copy to clipboard');
    const tooltipButton = ref(null);

    const copyToClipboard = (fileUrl) => {
      navigator.clipboard
        .writeText(fileUrl)
        .then(() => {
          // Indicate copy success
          copySuccess.value = true;
          tooltipText.value = 'Copied!';

          // Reset after 2 seconds
          setTimeout(() => {
            copySuccess.value = false;
            tooltipText.value = 'Copy to clipboard';
          }, 2000);
        })
        .catch((err) => {
          console.error('Failed to copy:', err);
        });
    };

    return {
      copySuccess,
      tooltipText,
      tooltipButton,
      copyToClipboard,
    };
  },
};
</script>
